import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Checkbox } from '@codebuild/cookie-jar/uikit/components/input/checkbox';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { Select } from '@codebuild/cookie-jar/uikit/components/input/select';
import { filter, find, get, map, uniqBy } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../helpers/errors';
import { priceFormat } from '../../helpers/price-format';
import { Subscribable } from '../../libs/subscribable';
import { EventCalendarComponent } from '../event-calendar/event-calendar.component';
import { MessageBox } from '../message-box/message-box';
import { ReservationStepper } from '../reservation-stepper/reservation-stepper';
import { Spinner } from '../spinner';
import { TicketSelectorComponent } from '../ticket-selector/ticket-selector.component';
import { BillingDataForm } from './billing-data.form';
import './reservation.component.scss';

export class ReservationComponent extends Subscribable<any, any> {
    public paymentOptions = [
        { type: 'ONLINE', title: trans('payment-option-online') },
        { type: 'IN_PLACE', title: trans('payment-option-in_place') }
    ];
    public billingDataFormRef;
    public ticketSelectorRef;
    public state: any = {
        selectedEvent: null,
        selectedTickets: [],
        errors: [],
        agreement: true,
        reservationSuccess: false,
        isLoading: false,
        paymentType: 'IN_PLACE',
        redeemedGiftTickets: [],
        redeemedCoupon: null,
        giftTicketError: null,
        couponError: null,
    };

    public form: Form = new Form({
        firstName: new Field({
            placeholder: '',
            label: trans('form-field-label-first_name'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        }),
        lastName: new Field({
            placeholder: '',
            label: trans('form-field-label-last_name'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        }),
        email: new Field({
            placeholder: '',
            label: trans('form-field-label-email'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required')),
                Validator.EMAIL('invalid.email.format')
            ]
        }),
        phone: new Field({
            placeholder: '',
            label: trans('form-field-label-phone'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        }),
        payment: new Field({
            label: trans('form-field-label-payment'),
            value: this.paymentOptions[1],
            options: this.paymentOptions,
            validators: [Validator.REQUIRED(trans('form-validator-required'))]
        }),
        comment: new Field({
            placeholder: '',
            label: trans('form-field-label-comment'),
            value: '',
            validators: []
        }),
        agreement: new Field({
            label: '',
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        })

    });

    public giftTicketForm: Form = new Form({
        code: new Field({
            placeholder: '',
            label: '',
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        })

    });

    public couponForm: Form = new Form({
        code: new Field({
            placeholder: '',
            label: '',
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        })

    });

    public componentDidMount() {

        this.subscriptions$.push(
            this.form.field('agreement').dirty$.subscribe((value) => {
                this.setState({ agreement: (this.form.field('agreement').dirty && !this.form.field('agreement').errors.length) || !this.form.field('agreement').dirty }, () => console.log(this.state.agreement));

            }),
            this.form.field('agreement').value$.subscribe((value) => {
                this.setState({ agreement: (this.form.field('agreement').dirty && !this.form.field('agreement').errors.length) || !this.form.field('agreement').dirty }, () => console.log(this.state.agreement));
            }),
            this.form.field('payment').value$.subscribe((v: any) => this.setState({ paymentType: v.type }))
        );
    }

    public render() {
        return <div className={'GiftTicketPurchaseComponent palette--bgc-neutral-1 elevation-1 relative px-8 py-8'}>
            {this.state.isLoading && <div className={'Loader'}><Spinner size={'large'} theme={'dark'}/></div>}
            <h1 className={'mb-8'}>{trans('reservation-component-title')}</h1>
            <div>{trans('reservation-component-en-disclaimer')}</div>
            <h4>{trans('reservation-component-subtitle-1')}</h4>
            <div className="col-24 mb-6">
                <EventCalendarComponent onEventClick={(data) => this.handleEventSelect(data)}/>
            </div>
            <div className="row mb-8" id={'TicketSelectorForm'}>
                <div className="col-lg-12 col-24">
                    <div className={'row'}>
                        <div className="col-24 mb-6">
                            <h4>{trans('reservation-component-subtitle-2')}</h4>
                            <div className="mb-4">
                                <MessageBox type={'info'} message={trans('reservation-component-info-online_payment')}/>
                            </div>
                            {get(this.state, 'selectedEvent') &&
                                <div>
                                    <div className={'mb-6'}>
                                        <TicketSelectorComponent ref={(ref) => this.ticketSelectorRef = ref} redeemedGiftTickets={get(this.state, 'redeemedGiftTickets', [])} limit={this.getAvailableTicketCount()}
                                                                 tickets={get(this.state, 'selectedEvent.extendedProps.type.supportedTicketTypes')} paymentType={this.state.paymentType}
                                                                 onTicketSelected={(value, data) => this.handleTicketSelected(value, data)}/>
                                    </div>
                                    <div>
                                        <div>
                                            {this.renderRedeemedGiftTickets()}
                                        </div>
                                        {this.renderGiftTicketForm()}
                                    </div>
                                    <div>
                                        {this.renderCouponForm()}
                                    </div>
                                </div>}
                            {!get(this.state, 'selectedEvent') && <MessageBox type={'info'} message={trans('reservation-component-info-choose_event')}/>}
                            {this.renderTicketDependencyErrors()}
                        </div>
                        <div className="col-24 mb-6"/>
                        <div className="col-24 mb-6">
                            <h4>{trans('reservation-component-subtitle-3')}</h4>
                            {!!get(this.state, 'selectedTickets', []).length && this.renderForm()}
                            {!get(this.state, 'selectedTickets', []).length && <MessageBox type={'info'} message={trans('reservation-component-info-choose_ticket')}/>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-24">
                    <MessageBox type={'info'} message={this.renderInfoContent()}/>
                </div>
            </div>
            <div className="row">
                {map(this.state.errors, (error) => <MessageBox type={'error'} message={getErrorMessage(error)}/>)}
            </div>
            <div className={'ReservationFooter'}>
                <ReservationStepper
                    onSubmit={() => this.handleSubmit()}
                    step={this.getCurrentStep()}
                    discount={get(this.state, 'redeemedCoupon.discount', 0) || 0}
                    price={this.state.paymentType === 'ONLINE' ? this.state.onlineSumPrice : this.state.sumPrice}
                />
            </div>
        </div>;
    }

    public renderGiftTicketForm() {
        return <div>
            <span>{trans('reservation-component-gift_ticket_code')}</span>
            <div className={'row w-100 mb-6'}>
                <div className="col-18">
                    <Input field={this.giftTicketForm.field('code')} className="variant-primary size-medium" type="text"/>
                </div>
                <div className="col-6">
                    <Button className={'variant-secondary size-medium'} title={trans('reservation-component-gift_ticket-button-title-redeem')} onClick={() => this.redeemGiftTicket()}/>
                </div>
            </div>
            <div>
                {this.renderGiftTicketError()}
            </div>
        </div>;
    }

    public renderRedeemedGiftTickets() {
        if (!this.state.redeemedGiftTickets.length) {
            return;
        }
        return <div className={'mb-6'}>
            <h5>{trans('reservation-component-redeemed_gift_tickets')}</h5>
            {map(this.state.redeemedGiftTickets, (t) => <div className={'row w-100'}>
                <div className="col-8"><span>{t.code}</span></div>
                <div className="col-8"><span>{t.ticketType.title}</span></div>
                <div className="col-8"><span>{priceFormat(t.ticketType.price)} Ft</span></div>
            </div>)}
        </div>;
    }

    public renderCouponForm() {
        if (!!this.state.redeemedCoupon) {
            return <div>
                <h5>{trans('reservation-component-redeemed_coupons')}</h5>
                <span className={'mr-2'}><span className={'fw-bold fs-small mr-1'}>{trans('reservation-component-coupon_code-label')}</span><span>{this.state.redeemedCoupon.code}</span></span>
                <span className={'mr-2'}><span
                    className={'fw-bold fs-small mr-1'}>{trans('reservation-component-coupon_discount-label')}</span><span>{Math.floor(parseFloat(get(this.state, 'redeemedCoupon.discount', 0)) * 100)}%</span></span>
            </div>;
        }
        return <div>
            <span>{trans('reservation-component-coupon_code-label')}</span>
            <div className={'row w-100 mb-6'}>
                <div className="col-18">
                    <Input field={this.couponForm.field('code')} className="variant-primary size-medium" type="text"/>
                </div>
                <div className="col-6">
                    <Button className={'variant-secondary size-medium'} title={trans('reservation-component-coupon-apply-button-title')} onClick={() => this.redeemCoupon()}/>
                </div>
            </div>
            <div>
                {this.renderCouponError()}
            </div>
        </div>;
    }

    public renderGiftTicketError() {
        console.log(this.state.giftTicketError);
        const isValidError = ['alreadyUsed', 'alreadyRedeemed', 'UnknownGiftTicket', 'expired', 'unsupportedTicketType'].includes(this.state.giftTicketError);
        if (!this.state.giftTicketError || !isValidError) {
            return;
        }
        let message = trans(`reservation-component-gift_ticket-error-${this.state.giftTicketError || 'default'}`);
        /*switch (this.state.giftTicketError) {
            case 'alreadyUsed':
                message = 'A kód már fel lett használva';
                break;
            case 'alreadyRedeemed':
                message = 'A jegy már be lett váltva';
                break;
            case 'unsupportedTicketType':
                message = 'A jegytípus ezen az eseményen nem foglalható';
                break;
            case 'UnknownGiftTicket':
                message = 'Érvénytelen kód';
                break;
        }*/
        return <div>
            <MessageBox type={'error'} message={message}/>
        </div>;
    }

    public renderCouponError() {
        console.log(this.state.couponError);
        const isValidError = ['alreadyUsed', 'alreadyRedeemed', 'UnknownCoupon'].includes(this.state.couponError);
        if (!this.state.couponError || !isValidError) {
            return;
        }
        let message = trans(`reservation-component-coupon-error-${this.state.couponError || 'default'}`);
        /*switch (this.state.couponError) {
            case 'alreadyUsed':
                message = 'A kód már fel lett használva';
                break;
            case 'alreadyRedeemed':
                message = 'A jegy már be lett váltva';
                break;
            case 'unsupportedTicketType':
                message = 'A jegytípus ezen az eseményen nem foglalható';
                break;
            case 'UnknownCoupon':
                message = 'Érvénytelen kód';
                break;
            default:
                message = 'Hiba történt';
                break;
        }*/
        return <div>
            <MessageBox type={'error'} message={message}/>
        </div>;
    }

    public async redeemGiftTicket() {
        try {
            this.giftTicketForm.dirty();
            const errors = await this.giftTicketForm.validate();

            if (!!errors.length) {
                return;
            }
            const code = this.giftTicketForm.toJSON().code;
            console.log(code);
            const ticket = await Repository.get(`/gift-ticket/byCode/${code}`);
            if (!ticket || !ticket._id) {
                throw new Error('invalid');
            }
            if (!!find(this.state.redeemedGiftTickets, (t) => t._id === ticket._id)) {
                throw new Error('alreadyUsed');
            }
            if (!!ticket.redeemed) {
                throw new Error('alreadyRedeemed');
            }

            if (moment(ticket?.createdAt).add(1, 'years').toDate() < moment().toDate()) {
                throw new Error('expired');
            }

            if (!find(get(this.state, 'selectedEvent.extendedProps.type.supportedTicketTypes', []), (t) => t._id === ticket.ticketType._id)) {
                throw new Error('unsupportedTicketType');
            }
            const redeemedGiftTickets = uniqBy([...this.state.redeemedGiftTickets, ticket], (t) => t._id);
            this.setState({ redeemedGiftTickets: redeemedGiftTickets });
            this.giftTicketForm.field('code').setValue(null);
            this.giftTicketForm.field('code').setDirty(false);
            this.setState({ giftTicketError: null });
            if (!!this.ticketSelectorRef) {
                this.ticketSelectorRef.addGiftTicket(ticket);
            }
        } catch (e) {
            this.setState({ giftTicketError: !!e?.response?.data?.message ? e?.response?.data?.message : e.message });
        }
    }

    public async redeemCoupon() {
        try {
            this.couponForm.dirty();
            const errors = await this.couponForm.validate();

            if (!!errors.length) {
                return;
            }
            const code = this.couponForm.toJSON().code;
            const coupon = await Repository.get(`/coupon/byCode/${code}`);

            if (!coupon || !coupon._id) {
                throw new Error('invalid');
            }

            if (!!find(this.state.redeemedCoupon, (t) => t._id === coupon._id)) {
                throw new Error('alreadyUsed');
            }

            this.setState({ redeemedCoupon: coupon });
            if (coupon.onlinePaymentOnly) {
                this.setState({ paymentType: 'ONLINE' });
                await this.form.field('payment').setOptions([{ type: 'ONLINE', title: trans('payment-option-online') }] as any);
                await this.form.field('payment').setValue({ type: 'ONLINE', title: trans('payment-option-online') });
            } else {
                await this.form.field('payment').setOptions(this.paymentOptions as any);
            }
            this.couponForm.field('code').setValue(null);
            this.couponForm.field('code').setDirty(false);
            this.setState({ couponError: null });
        } catch (e) {
            this.setState({ couponError: !!e?.response?.data?.message ? e?.response?.data?.message : e.message });
        }
    }

    private async getPayload() {
        await this.setState({ errors: [] },);
        if (!this.state.selectedEvent) {
            this.setState({ errors: [...this.state.errors, { type: 'MissingEvent' }] });
        }
        if (!this.state.selectedTickets.length) {
            this.setState({ errors: [...this.state.errors, { type: 'MissingTicket' }] });
        }

        if (this.state.errors.length) {
            return;
        }

        await this.form.dirty();
        const errors = await this.form.validate();
        let billingData = null;

        if (get(this.state, 'paymentType') === 'ONLINE') {
            const billingValidation = await this.billingDataFormRef.validate();
            if (!billingValidation) {
                return;
            }

            billingData = this.billingDataFormRef.getData();
        }

        if (errors.length) {
            return;
        }
        console.log(this.state.redeemedCoupon);
        const data = this.form.toJSON();
        data.payment = this.state.sumPrice <= 0 ? { type: 'IN_PLACE' } : data.payment;
        return {
            ...data,
            ticketTypes: this.state.selectedTickets,
            giftTickets: this.state.redeemedGiftTickets,
            event: this.state.selectedEvent.extendedProps._id,
            coupon: get(this.state, 'redeemedCoupon._id', null),
            billingData: billingData
        };
    }

    private async handleSubmit() {
        try {
            this.setState({ isLoading: true });
            const payload = await this.getPayload();
            if (!payload) {
                return;
            }
            console.log('PAYLOAD', payload);

            const response = await Repository.post('/reservation-public', payload);

            if (!response) {
                throw Error('SomethingWentWrong');
            }

            if (get(response, 'payment.type') === 'IN_PLACE' && response.instance) {
                this.setState({ reservationSuccess: true });

                window.location.href = `/reservation-callsheet?pt=${response.instance.publicToken}&rid=${response.instance._id}`;
            } else if (get(response, 'payment.type') === 'ONLINE' && response.gatewayCredentials && response.gatewayCredentials.popupWindowUrl && response.instance) {
                window.location.href = response.gatewayCredentials.popupWindowUrl;
            } else {
                throw new Error('UnknwoResult');
            }

        } catch (err) {
            this.setState({ reservationSuccess: false, isLoading: false });
            map(get(err, 'response.data.payload.errors') || [], (e) => this.setState({ errors: [...this.state.errors, e] }));
            console.log(this.state.errors);
        } finally {
            toast.error('Nem sikerült végrehajtani a műveletet');
            this.setState({ isLoading: false });
        }
    }

    public handleEventSelect(data) {
        this.setState({ selectedEvent: data, selectedTickets: [] }, () => {
            $('html, body').animate({
                scrollTop: (($('#TicketSelectorForm') as any).offset().top - 100)
            }, 500);
        });
    }

    public renderTicketDependencyErrors() {
        const dependencyErrors: any[] = filter(this.state.errors, (e) => e.type === 'MissingTicketTypeDependency');
        if (!dependencyErrors.length) {
            return;
        }
        return <div className={'display-flex flex-column w-100 p-box border-1 palette--bc-red-2 palette--bgc-red-1 border-radius-1'}>
            {dependencyErrors.map((e) => {
                return <div className={'display-flex flex-column mb-6'}>
                    <span className={'mb-4'}>A <strong>{get(e, 'payload.ticketType.title')}</strong> jegyhez az  alábbi jegyek szükségesek:</span>
                    {get(e, 'payload.dependencies', []).map((d) => {
                        return <span className={'pl-6'}>- {get(d, 'title')}</span>;
                    })}
                </div>;
            })}
        </div>;
    }

    public getCurrentStep() {
        let step = 0;
        if (!!this.state.selectedEvent) {
            step = step + 1;
        }
        if (!!this.state.selectedTickets.length) {
            step = step + 1;
        }

        return step;
    }

    private handleTicketSelected(tickets, data) {
        let selectedTickets: any[] = [];
        map(tickets, (count, key) => {
            selectedTickets = [...selectedTickets, ...Array(count).fill(key)];
        });
        const currentPaymentType = this.state.paymentType;
        this.setState({ selectedTickets: selectedTickets, sumPrice: data.sum, onlineSumPrice: data.onlineSum, paymentType: (data.sum > 0 ? currentPaymentType : 'IN_PLACE') });
    }

    private renderForm() {
        return <AbstractForm className="w-100" form={this.form} onSubmitSuccess={() => this.props.onSuccess(this.form.toJSON())}>
            <div className="row">
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('lastName')} className="variant-primary size-medium" type="text"/>
                </div>
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('firstName')} className="variant-primary size-medium" type="text"/>
                </div>
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('email')} className="variant-primary size-medium" type="email"/>
                </div>
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('phone')} className="variant-primary size-medium" type="text"/>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-12 mb-4">
                    <Select
                        valueProperty="type"
                        preventDropdownOpened={this.state.sumPrice <= 0}
                        disabled={this.state.sumPrice <= 0}
                        field={this.form.field('payment')}
                        className="size-medium variant-primary mb-2"
                    />
                </div>
            </div>


            {get(this.state.payment, 'type') === 'ONLINE' && <div className="row">
                <div className="col-24 col-md-12 mb-4">
                    <img src="/assets/barion-card-strip-intl_1200px.png" width="100%" alt=""/>
                </div>
            </div>}

            <div className="row">
                <div className="col-24 mb-4">
                    <Input
                        element={'textarea'}
                        rows={6}
                        field={this.form.field('comment')}
                        className="variant-primary size-medium"
                        type="text"
                    />
                </div>
            </div>
            {get(this.state, 'paymentType') === 'ONLINE' && <div className={'mb-4'}>
                <h5>{trans('payment-form-title')}</h5>
                <BillingDataForm ref={(ref) => this.billingDataFormRef = ref}/>
            </div>}
            <div className="row">
                <div className="col-24 mb-4">
                    <div className="display-flex flex-row justify-content-start align-items-center">
                        <Checkbox
                            disabled={false}
                            field={this.form.field('agreement')}
                            className="size-small variant-primary mr-4"
                            inputClassName={'display-none'}
                        />
                        <div>{trans('form-accept-agreement')}</div>
                    </div>
                    {!this.state.agreement && <span className={'palette--c-red-2'}>{trans('form-validator-required')}</span>}
                </div>
            </div>
        </AbstractForm>;
    }

    public renderInfoContent() {
        return <div>
            {trans('reservation-component-visitor-disclaimer')}
        </div>;
    }

    private getAvailableTicketCount() {

        const numberOfReservations = get(this.state, 'selectedEvent.extendedProps.numberOfReservations', 0);
        const participantLimit = get(this.state, 'selectedEvent.extendedProps.max');
        return participantLimit - numberOfReservations;
    }
}
