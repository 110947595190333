import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import './call-sheet.screen.scss';

export class CallSheetScreen extends React.Component<any, {}> {
    public render() {
        return <div className={'container py-8'}>
            <div className={'CallSheetScreen palette--bgc-neutral-1 elevation-1 px-8 py-8'}>
                <div className="">
                    <h2 className={'mb-8'}><span className={'material-icons SuccessIcon mr-4'}>check</span>{trans('call-sheet-reservation-success-title')}</h2>
                    <span className={'palette--c-neutral-5 fs-medium'}>{trans('call-sheet-reservation-success-description')}</span>
                </div>
            </div>
        </div>;
    }
}
